@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --white: #dbe0eb;
  --greyinput: #192131;
  --grey10: #7e8aa1;
  --greyactive: #242f44;
  --grey20: #1d2637;
  --grey30: #0d121c;
  --greyborder: #07090e;
  --red10: #dc2a59;
}

::-webkit-scrollbar {
  height: 6px;
  background-color: var(--grey20);
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--grey10);
  border-radius: 20px;
  cursor: grab;
}


* {
  -webkit-tap-highlight-color: transparent;

}




body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--white);

  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
}

svg {
  display: flex;
  align-items: center;
}

img {
  display: flex;
  align-items: center;
  justify-content: center;
}



h1 {

  text-align: left;
  font-size: 26px;
  font-weight: 500;
}

p {

  color: hsl(var(--nextui-default-700));
}



h2 {
  font-size: 14px;
  text-transform: uppercase;
  margin: 0;
  color: hsl(var(--nextui-default-400));
  font-weight: 500;
  text-align: left;
}



a {
  cursor: pointer;
}


.HourSetRise {
  color: var(--white);
  margin: 5px;
  text-align: left;
}




.sign-in-container h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.sign-in-container {
  max-width: 30em;
  margin: auto;
}




.btn_unit button.active {
  /* styles pour mettre le bouton en surbrillance ici */
  color: var(--white);
  /* par exemple */
  transition: 0.2s;
}


.btn_unit button {
  font-size: 20px;
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: var(--grey10);
  font-weight: 500;
}

.btn_unit button:hover {
  background-color: transparent;
}

.btn_unit {
  justify-content: center;
  gap: 20px;
  padding-inline: 10px;
}


.navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  color: var(--white);
  border-top: #192131 solid 1px;
  backdrop-filter: blur(20px);

}


.navbar ul {
  list-style-type: none;
  margin: 0;
  display: flex;
  justify-content: space-evenly;

}

.navbar li {
  float: left;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  letter-spacing: 0.2px;
}


a:active {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  transition: 0.2s;
}


.hourly-forecast {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}



.nav-link {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}


.nav-link.active {
  color: var(--white);
  transition: 0.2s;
}






.search-bar-container {
  display: flex;
  align-items: center;
  gap: 5px;
}





.pac-container {
  background-color: var(--grey20);
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: transparent;
  z-index: 10002;
  width: 100%;
}

.pac-matched,
.pac-item-query {
  color: var(--white);
  font-size: 16px;
}

.pac-item {
  color: var(--grey10);
  padding-block: 10px;
  font-size: 14px;
  border: var(--grey10) solid 0px;
  padding-inline: 15px;
  cursor: pointer;
}

.pac-item:hover {
  background-color: var(--greyactive);
  transition: 0.2s;
}


.pac-icon {
  display: none;
}



.location-component {
  display: flex;
  gap: 4px;
  align-items: center;

}


.location-component p {
  margin: 0;

  color: var(--white);
}





.ContainerTonightCloud {
  animation-name: cloudScroll;
  animation-duration: 35s;
  /* Durée de l'animation */
  animation-timing-function: linear;
  /* Fonction de temporisation (linéaire pour un défilement régulier) */
  animation-iteration-count: infinite;
  /* Répétition infinie */
}



.badgeGoodnight {
  padding: 10px;

  background-color: #75f4c6;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #172f26;

}



.badgeBadnight {
  padding: 10px;

  background-color: #200c11;
  color: var(--red10);
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;
  border: solid 1px #3a1b24;
}

.badgeBadnight p {
  color: #f37592;
  font-size: 16px;
}

.badgeGoodnight p {
  color: #0e1f19;
  font-size: 16px;
}


.FlexClass {
  display: flex;
  align-items: center;
}

.ContainerHour {
  display: flex;
  justify-content: space-around;
  align-items: center;
  display: flex;


}









.ContainerDay {
  display: flex;
  position: relative;
  padding-inline: 18px;
  padding-block: 22px;

  text-align: center;
  border-radius: 10px;
  color: var(--grey10);
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 18px;
  border: solid 1px var(--grey20);
  cursor: pointer;
  height: 100%;
}




.selectedDay {
  background-color: var(--grey20);
  transition: 0.2s;
}


summary::-webkit-details-marker {
  display: none;
}

summary {
  position: relative;
  padding-right: 20px;
  cursor: pointer;
}

summary:hover {
  background: var(--greyinput);
  transition: 0.2s;
}



summary::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-image: url(./assets/expand_less_FILL0_wght500_GRAD0_opsz48.svg);
  background-size: cover;
  transition: transform 0.3s ease-out;
  transform: translateY(-50%) rotate(180deg);
  margin-right: 15px;


}

details[open]>summary::after {
  transform: translateY(-50%) rotate(0deg);

}

details[open] {
  margin-bottom: 30px;
}


.Ratingbadge {
  padding: 3px;
  border-radius: 50px;
  padding-inline: 8px;
  display: flex;
  align-items: center;
  gap: 2px;
  /*border: 1px solid var(--greyinput);*/
}

.cloudBadge {
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 2px;
  height: fit-content;
  text-align: right;
}

.Ratingbadge p {
  margin: 0;
}




.ContainerText {
  background-color: var(--grey20);
  padding: 15px;
  border-radius: 5px;
  text-align: left;
}





.btn-secondary {
  background-color: var(--greyinput);
}




@keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}


.containerLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* pour prendre toute la hauteur de la fenêtre de visualisation */
}

.loader {
  width: 40px;
  height: 40px;
  border: 2px solid var(--grey10);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  background: var(--grey10);
  width: 14px;
  height: 14px;
  transform: translate(-50%, 50%);
  border-radius: 50%;
}


.loaderLogin {
  border: 2px solid var(--white);
  border-radius: 50%;
  border-top: 2px solid #4457FF;
  width: 14px;
  height: 14px;
  animation: spin 2s linear infinite;
  margin: auto;
}


.loaderButton {
  border: 2px solid var(--white);
  border-radius: 50%;
  border-top: 2px solid var(--grey20);
  width: 14px;
  height: 14px;
  animation: spin 2s linear infinite;
  margin: auto;
}


.btn-primary-loading {
  /* Mettez ici la couleur que vous voulez quand le bouton est désactivé */
  cursor: not-allowed;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.Logo-header {
  display: none;
}

.favorite-city {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 4px;
  padding-inline-start: 10px;
  z-index: 1;
  border-top: 1px solid var(--grey20);
  background-color: var(--greyinput);
}


.favorite-city:hover {
  background-color: var(--greyactive);
  transition: 0.2s;
}

.favorite-city svg {
  width: 20px;
}

.favorite-city p {
  color: var(--white);
}


a {
  color: var(--white);
  text-decoration: none;
}



@media (min-width: 1024px) {




  .ContainerTonight {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .ContainerTonightCloud h1 {
    font-size: 32px;
  }

  .ContainerTonightIndividual h1 {
    font-size: 22px;
  }


  h2 {
    font-size: 14px;
  }

  h3 {
    font-size: 16px;
  }



  p.selected-city {
    font-size: 18px;
  }



  .ContainerHour p {
    font-size: 16px;
  }




  .ContainerMoon p {
    margin: 5px;
  }

  .navbar {

    height: 100%;

  }

  .navbar ul {
    flex-direction: column;
    gap: 10px;
  }

  .nav-link.active {

    border-radius: 10px;
    transition: 0.2s;
  }

  .Content {
    margin: auto;
    width: 60%;

  }

  .nav-link {
    flex-direction: unset;

    gap: 10px;
  }

  .navbar ul li a {
    font-size: 15px;
  }

  .navbar ul li svg {
    width: 20px;
  }


  .nav-item:hover {
    background-color: var(--grey20);
    border-radius: 10px;
    transition: 0.2s;
  }




  input.search_bar {
    width: 15em;
    height: 40px;
    transition: 0.2s;
  }


  input.search_bar:focus {
    width: 25em;
    transition: 0.2s;
  }


  .header {
    justify-content: space-between;
    align-items: center;
    position: unset;
    width: 60%;
    background-color: black;
    top: 0;
    padding: 15px;
    box-sizing: unset;
    z-index: 1;
    display: flex;
    margin: auto;
  }



  .Logo-header {
    display: block;
    padding: 25px;
    border-bottom: 1px solid var(--grey20);
  }

  .Logo-header img {
    margin: auto;
  }

  .btn-profile {
    display: flex;
    gap: 10px;
  }
}


.ContainerMoon {
  background-color: var(--grey20);
  border-radius: 5px;
  text-align: left;
  padding: 15px;
  margin-top: 10px;

}

.ContainerPlanet {
  background-color: var(--grey20);
  border-radius: 5px;
  text-align: left;
  padding: 15px;
  margin-top: 10px;
  box-sizing: border-box;
  display: flex;
  overflow: auto;
  flex-direction: column;

}





/* Skeleton //////*/

.ContainerSkeleton {
  border-radius: 5px;
  background-color: var(--grey20);
  background-color: -webkit-color(--grey20);

  background: linear-gradient(100deg,
      rgba(255, 255, 255, 0) 60%,
      rgb(62, 77, 106) 70%,
      rgba(255, 255, 255, 0) 80%) #28354f;

  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1.5s loading ease-in-out infinite;
}

.ContainerHourSkeleton {
  border-radius: 5px;
  background-color: var(--grey20);
  animation: 1.5s loading ease-in-out infinite;
}

@keyframes loading {
  0% {
    opacity: 0.4;
  }

  /* le fond commence à moitié transparent */
  50% {
    opacity: 0.7;
  }

  /* puis devient complètement opaque */
  100% {
    opacity: 0.4;
  }

  /* et revient à moitié transparent */
}



@keyframes cloudScroll {
  0% {
    background-position: 0% 0;
    /* Position de départ de l'image de fond */
  }

  100% {
    background-position: 100% -50%;
    /* Position finale de l'image de fond (défilement complet) */
  }
}


.container-favorable {
  background-color: #0d1b1a;


}

.hour-favorable {
  color: #41f6c3;
}

.leaflet-control-zoom {
  display: none;
}

.leaflet-tile-container .light-pollution-tiles {
  filter: blur(50px);
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;

}

.custom-slider::-moz-range-thumb {
  background: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
}

.stripeProduct {
  display: flex;
  justify-content: center;
  margin: auto;
  background-color: var(--grey20);
  padding-block: 20px;
  border-radius: 10px;
  padding-inline: 20px;
  border: 1px solidrgb(29 38 53);
}

div.stripeProduct>div>div:nth-child(2) p {
  margin-block: 6px;
}


#root>div>div.Content>div>div:nth-child(1)>div>div>div>div:nth-child(2) p {
  margin-block: 6px;
}

@keyframes rain-fall {
  0% {
    top: -10%;
    /* Commence au-dessus de l'écran */
  }

  100% {
    top: 100%;
    /* Va jusqu'en bas de l'écran */
  }
}

#root > div > div > div > div > div:nth-child(2) > div:nth-child(2) > div > div.px-2.w-full {
  padding: 0;
}






.shiny-button:before {
  content: '';
  position: absolute;
  width: 600px;
  height: 100%;
  background-image: linear-gradient(
      120deg,
      rgba(255,255,255, 0) 30%,
      rgba(255,255,255, .2),
      rgba(255,255,255, 0) 70%
  );
  top: 0;
  left: -500px;
  animation: shine 3s infinite linear; /* Animation */
}



@keyframes shine {
  0% {left: -500px}
  20% {left: 100%}
  100% {left: 100%}
}
